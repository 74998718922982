import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Box,
  Typography,
  MenuItem,
  Menu,
  Badge,
  IconButton,
} from "@mui/material";
import { getTranslation } from "common";
import {
  DoingPanel,
  ToDoPanel,
  DonePanel,
  LabelSearchSelect,
} from "components";
import PrioritySearchSelect from "components/priority/PrioritySearchSelect";
import {
  useActivities,
  useSelectedOrganization,
  useTranslations,
  useUser,
  usePriorities,
  // useProcessModels,
} from "hooks";
import useUserDataKeys from "hooks/useUserDataKeys";
import { StartProcess, TaskDetailDialog } from "components/dialogs";
import {
  DragDropContext,
  OnDragEndResponder,
  OnDragUpdateResponder,
} from "react-beautiful-dnd";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { CONSTANTS, filterTasks, processTasks } from "common/utilities";
import { organizationsApi, processesApi } from "api";
import { SequenceFlow, ServerTag, Task } from "model/activitiesModel";
import { Colorize, Search, Title } from "@mui/icons-material";
//import { createPortal } from "react-dom";
import UserDataFilter from "components/UserDataFilter/UserDataFilter";
import ProcessModelFilter from "components/ProccessModelFilter/ProcessModelFilter";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import { SortState } from "hooks/useSortableList";
import { SortCriterion } from "common/utilities";
import { ProcessModelType } from "model/processModel";
import { Priority } from "hooks/usePriorities";

const LOCAL_STORAGE_KEY = `myActivitiesFilters`;

const MyActivities = () => {
  const translations = useTranslations();
  const organization = useSelectedOrganization();
  const [completeLabel, setCompleteLabel] = useState<boolean>(false);
  const [tasks, setTasks, orderedArray] = useActivities(organization);
  const user = useUser();
  const [searchText, setSearchText] = useState<string>("");
  // const [undoSnackbar, setUndoSnackbar] = useState<boolean>(false);
  const [searchTags, setSearchTags] = useState<number[]>([]);
  const [tagList, setTagList] = useState<ServerTag[]>([]);
  const [all] = useState<boolean>(false);
  const [startProcess, setStartProcess] = useState(false);
  const [switchChecked] = useState(false);
  const [dragItem, setDragItem] = useState<Task | null>(null);
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
  const [priorities] = usePriorities(organization?.id || null);
  // const { processModels } = useProcessModels();
  const [selectedModels, setSelectedModels] = useState<number[]>([]);
  const [selectedUserDataKey, setSelectedUserDataKey] = useState<string | null>(
    null
  );
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null);
  const [userDataKeys] = useUserDataKeys(tasks);
  const selectedOrganization = useSelectedOrganization();
  const [processModels, setProcessModels] = useState<ProcessModelType[]>([]);
  const [sortCriteria, setSortCriteria] = useState<SortCriterion>({});
  const [showAllTags, setShowAllTags] = useState(false);
  const [showOnlyIdUserZero, setShowOnlyIdUserZero] = useState(false);

  //ci arriva da TaskDetailDialog
  const updateTaskPriority = useCallback(
    (taskId: number, newPriority: Priority) => {
      setTasks((prevTasks) => ({
        todo: prevTasks.todo.map((task) =>
          task.idprocesstoken === taskId
            ? { ...task, priority_name: newPriority.name }
            : task
        ),
        doing: prevTasks.doing.map((task) =>
          task.idprocesstoken === taskId
            ? { ...task, priority_name: newPriority.name }
            : task
        ),
      }));
    },
    [setTasks]
  ); //può essere vuoto?

  useEffect(() => {
    setCompleteLabel(
      JSON.parse(
        localStorage.getItem(CONSTANTS.STORE_LABEL_COMPLETE_MODE) || "false"
      )
    );
  }, []);

  useEffect(() => {
    const savedFilters = localStorage.getItem(
      `${LOCAL_STORAGE_KEY}-${organization?.id}`
    );
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      setSearchText(filters.searchText || "");
      setSearchTags(filters.searchTags || []);
      setSelectedPriorities(filters.selectedPriorities || []);
      setSelectedModels(filters.selectedModels || []);
      setSelectedUserDataKey(filters.selectedUserDataKey || null);
      setSortCriteria(filters.sortCriteria || {});
    }
  }, [organization]);

  useEffect(() => {
    const filters = {
      searchText,
      searchTags,
      selectedPriorities,
      selectedModels,
      selectedUserDataKey,
      sortCriteria,
    };
    localStorage.setItem(
      `${LOCAL_STORAGE_KEY}-${organization?.id}`,
      JSON.stringify(filters)
    );
  }, [
    organization,
    sortCriteria,
    searchText,
    searchTags,
    selectedPriorities,
    selectedModels,
    selectedUserDataKey,
  ]);

  const handleSelectUserDataKey = (selectedKey: string | null) => {
    if (selectedKey) {
      setSelectedUserDataKey(selectedKey);
    } else {
      setSortCriteria((prev) => ({
        ...prev,
        userdata: SortState.None,
      }));
    }
  };

  // useEffect(() => {
  //   if (!sortCriteria.userdata) {
  //     setSelectedUserDataKey(null);
  //   }
  // }, [sortCriteria]);

  const SUPPORTED_SORTS = [
    {
      field: "priority",
      label: (userDataKey: string | null) =>
        getTranslation(translations, "generic.sort.by.priority"),
    },
    {
      field: "name",
      label: (userDataKey: string | null) =>
        getTranslation(translations, "generic.sort.by.process_name"),
    },
    {
      field: "startDate",
      label: (userDataKey: string | null) =>
        getTranslation(translations, "generic.sort.by.startdate"),
    },
    {
      checkDisabled: function (userDataKey: string | null) {
        return !Boolean(userDataKey);
      },
      field: "userdata",
      label: (userDataKey: string | null) =>
        `${getTranslation(translations, "generic.sort.by.userdatakey")} ${
          userDataKey || ""
        }`,
    },
  ];

  useEffect(() => {
    if (selectedOrganization?.id) {
      organizationsApi
        .get(`/${selectedOrganization.id}/processmodels/v2`)
        .then((res) => {
          setProcessModels(
            (res.data.processmodels as ProcessModelType[]).filter(
              (a) => a.modeltype
            )
          );
        })
        .catch((error) => {});
    }
  }, [selectedOrganization]);
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSort = () => {
    setSortAnchorEl(null);
  };

  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
  };

  const handleSortOptionClick = (field: string) => {
    setSortCriteria((prevState) => ({
      ...prevState,
      [field]:
        prevState[field] === SortState.Asc
          ? SortState.Desc
          : [SortState.None, undefined].includes(prevState[field])
          ? SortState.Asc
          : SortState.None,
    }));
  };

  useEffect(() => {
    if (organization) {
      (async () => {
        try {
          const { data } = await organizationsApi.get<{
            tag: { name: string; color: string; id: number }[];
          }>(`${organization?.id}/tags`);
          setTagList(
            data.tag.map((tag) => ({
              color: tag.color,
              idtag: tag.id,
              tag: tag.name,
            }))
          );
        } catch {
        } finally {
        }
      })();
    }
  }, [organization, tasks]);

  const orderArray = useCallback(
    (
      source: "todo" | "doing",
      dest: "todo" | "doing",
      sourceIdx: number,
      destIdx: number | null = null
    ) => {
      const _tasks = { ...tasks };
      const [task] = _tasks[source].splice(sourceIdx, 1);
      if (destIdx !== null) {
        task.state = source === "todo" ? 1 : 0;
        _tasks[dest].splice(destIdx, 0, task);
      }
      setTasks(_tasks);
      orderedArray.current = {
        todo: _tasks.todo.map((_) => _.idprocesstoken),
        doing: _tasks.doing.map((_) => _.idprocesstoken),
      };
    },
    [orderedArray, setTasks, tasks]
  );

  const fromTodoTo_Doing = async (task: Task) => {
    try {
      await processesApi.post(`/${task.idprocesstoken}/nextstate/doing`);
    } catch {
    } finally {
      // getTasks(all);
    }
  };
  const fromDoingTo_Done = async (task: Task) => {
    try {
      await processesApi.post(`/${task.idprocesstoken}/nextstate/done`);
    } catch {
    } finally {
      // getTasks(all);
    }
  };
  const fromDoingTo_Todo = async (task: Task) => {
    try {
      await processesApi.post(`/${task.idprocesstoken}/undo`);
    } catch {
    } finally {
      // getTasks(all);
    }
  };
  const fromDoingTo_Sequence = async (
    task: Task,
    sequenceFlow: SequenceFlow
  ) => {
    try {
      await processesApi.post(
        `tokens/${task.idprocesstoken}/exit/${sequenceFlow.designerid}`
      );
    } catch {
    } finally {
      // getTasks(all);
    }
  };

  const filteredTags = useMemo(() => {
    return tagList.filter((tag) =>
      tasks.todo
        .concat(tasks.doing)
        .some((task) => task.jsontag?.includes(tag.idtag as unknown as string))
    );
  }, [tagList, tasks.todo, tasks.doing]);

  const handleDragUpdate: OnDragUpdateResponder = ({ destination }) => {
    if (destination && destination.droppableId === "done") {
      setDragItem((_) => {
        if (
          _?.sequenceflows &&
          _?.sequenceflows.length > 0 &&
          destination.index < _.sequenceflows.length
        ) {
          const _sequence = [..._?.sequenceflows].map((_) => ({
            ..._,
            isOver: false,
          }));
          if (_sequence[destination.index]) {
            _sequence[destination.index].isOver = true;
          }
          return {
            ..._,
            isOverDone: false,
            sequenceflows: _sequence,
          };
        }
        return !_
          ? null
          : {
              ..._,
              isOverDone:
                destination.droppableId === "done" && destination.index === 0,
            };
      });
    } else {
      setDragItem((_) => {
        if (_?.sequenceflows && _?.sequenceflows.length > 0) {
          const _sequence = [..._?.sequenceflows].map((_) => ({
            ..._,
            isOver: false,
          }));
          return {
            ..._,
            isOverDone: false,
            sequenceflows: _sequence,
          };
        }
        return !_ ? null : { ..._, isOverDone: false };
      });
    }
  };
  const handleDragEnd: OnDragEndResponder = async ({
    reason,
    source,
    destination,
  }) => {
    // setUndoSnackbar(true);
    if (dragItem && destination && reason !== "CANCEL") {
      if (source.droppableId === "doing") {
        if (destination.droppableId === "done") {
          if (dragItem.sequenceflows && dragItem.sequenceflows.length > 0) {
            if (dragItem.sequenceflows.length > destination.index) {
              orderArray("doing", "todo", source.index);
              await fromDoingTo_Sequence(
                dragItem,
                dragItem.sequenceflows[destination.index]
              );
            }
          } else {
            orderArray("doing", "todo", source.index);
            await fromDoingTo_Done(dragItem);
          }
        } else {
          orderArray("doing", "todo", source.index, destination.index);
          await fromDoingTo_Todo(dragItem);
        }
      } else {
        orderArray("todo", "doing", source.index, destination.index);
        await fromTodoTo_Doing(dragItem);
      }
    }
    setDragItem(null);
  };

  const [detail, setDetail] = useState<number | null>(null);

  //   React Hook useMemo has a spread element in its dependency array. This means we can't statically verify whether you've passed the correct dependencies.eslintreact-hooks/exhaustive-deps
  // const taskDependencies = [
  //     searchText, searchTags, selectedPriorities,
  //     userDataKey, userDataValue, processModelName, sortCriteria
  //   ];

  const processedToDoTasks = useMemo(
    () =>
      processTasks(
        tasks.todo,
        searchText,
        searchTags,
        selectedPriorities,
        selectedUserDataKey,
        selectedModels,
        sortCriteria,
        priorities
      )
        .filter(
          ({ idprocesstoken }) => idprocesstoken !== dragItem?.idprocesstoken
        )
        .filter((task) => !showOnlyIdUserZero || task.iduser === 0),
    [
      dragItem,
      tasks.todo,
      searchText,
      searchTags,
      selectedPriorities,
      selectedUserDataKey,
      selectedModels,
      sortCriteria,
      priorities,
      showOnlyIdUserZero,
    ]
  );
  const processedDoingTasks = useMemo(
    () =>
      processTasks(
        tasks.doing,
        searchText,
        searchTags,
        selectedPriorities,
        selectedUserDataKey,
        selectedModels,
        sortCriteria,
        priorities
      ).filter(
        ({ idprocesstoken }) => idprocesstoken !== dragItem?.idprocesstoken
      ),
    // .filter((task) => !showOnlyIdUserZero || task.iduser === 0),
    [
      dragItem,
      tasks.doing,
      searchText,
      searchTags,
      selectedPriorities,
      selectedUserDataKey,
      selectedModels,
      sortCriteria,
      priorities,
      //   showOnlyIdUserZero
    ]
  );
  const appliedFilter = useMemo(() => {
    return (
      (selectedUserDataKey ? 1 : 0) + //possibly null
      searchTags.length +
      (searchText ? 1 : 0) + //il counter dei filtri partiva da 000
      selectedModels.length +
      selectedPriorities.length
    );
  }, [
    selectedUserDataKey,
    searchTags,
    searchText,
    selectedModels,
    selectedPriorities,
  ]);

  // const appliedFilter = useMemo(
  //   () =>
  //     [
  //       selectedUserDataKey,
  //       searchTags.length,
  //       searchText,
  //       selectedModels.length,
  //       selectedPriorities.length,
  //     ].filter((_) => _).length,
  //   [
  //     selectedUserDataKey,
  //     searchTags,
  //     searchText,
  //     selectedModels,
  //     selectedPriorities,
  //   ]
  // );

  const displayedTags = useMemo(() => {
    return showAllTags ? filteredTags : filteredTags.slice(0, 5);
  }, [filteredTags, showAllTags]);

  const selectedTask = useMemo(() => {
    return (
      tasks.todo.find((_) => _.idprocesstoken === detail) ||
      tasks.doing.find((_) => _.idprocesstoken === detail)
    );
  }, [tasks, detail]);

  const handleClearFilters = useCallback(
    (disableLocalStorage = false) => {
      setSearchText("");
      setSearchTags([]);
      setSelectedPriorities([]);
      setSelectedModels([]);
      setSelectedUserDataKey(null);
      setSortCriteria({});
      if (!disableLocalStorage) {
        localStorage.removeItem(`${LOCAL_STORAGE_KEY}-${organization?.id}`);
      }
    },
    [organization]
  );

  //anche questo sarà da rivedere per usarne solo uno (vedi ProcessStarted)
  useEffect(() => {
    return () => handleClearFilters(true);
  }, [handleClearFilters]);

  if (organization && organization.id && user) {
    return (
      <React.Fragment>
        <TaskDetailDialog
          doing={selectedTask?.state === 1}
          task={selectedTask}
          open={Boolean(selectedTask)}
          onClose={() => setDetail(null)}
          handleNextStep={
            selectedTask?.state === 1 ? fromDoingTo_Done : fromTodoTo_Doing
          }
          handleSequenceFlowChoice={
            selectedTask?.state === 1 ? fromDoingTo_Sequence : undefined
          }
          handleBackStep={
            selectedTask?.state === 1 ? fromDoingTo_Todo : undefined
          }
          administrator={organization.administrator}
          onPriorityUpdate={updateTaskPriority}
        />

        <StartProcess
          isAdmin={organization.administrator}
          open={startProcess}
          setOpen={setStartProcess}
        />
        <DragDropContext
          onDragUpdate={handleDragUpdate}
          onDragEnd={handleDragEnd}
          onBeforeDragStart={(e) => {
            setDragItem(
              tasks[e.source.droppableId as "todo" | "doing"].filter(
                filterTasks(
                  searchText,
                  searchTags,
                  selectedPriorities,
                  selectedUserDataKey,
                  selectedModels
                )
              )[e.source.index]
            );
          }}
        >
          <Stack sx={{ overflow: "hidden" }} height="100%" spacing={1}>
            <Stack
              direction="row"
              sx={{
                pt: 1.1,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                id="startprocess-button"
                size="small"
                variant="outlined"
                sx={{
                  height: 40,
                }}
                onClick={() => setStartProcess(true)}
              >
                {getTranslation(
                  translations,
                  "pm.startprocess.button.ok.caption"
                )}
              </Button>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                marginTop={2}
              >
                <IconButton
                  onClick={() => {
                    setCompleteLabel((currentCompleteLabel) => {
                      localStorage.setItem(
                        CONSTANTS.STORE_LABEL_COMPLETE_MODE,
                        `${!currentCompleteLabel}`
                      );
                      return !currentCompleteLabel;
                    });
                  }}
                >
                  {completeLabel ? <Title /> : <Colorize />}
                </IconButton>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="primary"
                  badgeContent={appliedFilter}
                >
                  <Button
                    id="filter-button"
                    size="small"
                    onClick={handleFilterClick}
                    endIcon={<FilterListIcon />}
                    variant="outlined"
                  >
                    {getTranslation(translations, "generic.filters.caption")}
                  </Button>
                </Badge>
                <Menu
                  id="filter-popover"
                  open={Boolean(filterAnchorEl)}
                  anchorEl={filterAnchorEl}
                  onClose={handleCloseFilter}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box p={2} sx={{ overflowX: "hidden" }}>
                    <Stack direction="column" spacing={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <TextField
                          onKeyDown={(e) => e.stopPropagation()}
                          id="search-tasks-text"
                          variant="outlined"
                          sx={{ flex: 1 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Search />
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          label={getTranslation(
                            translations,
                            "pm.organizationchart.ricerca.placeholder"
                          )}
                          value={searchText}
                          onChange={(e) => {
                            e.preventDefault();
                            setSearchText(e.target.value);
                          }}
                        />
                        <IconButton
                          color="primary"
                          onClick={() => handleClearFilters()}
                        >
                          <FilterListOffIcon />
                        </IconButton>
                      </Box>
                      <Box minWidth={200}>
                        {processModels.length > 0 && (
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight="bold"
                          >
                            {getTranslation(
                              translations,
                              "generic.filter.by.processmodel"
                            )}
                          </Typography>
                        )}
                        <ProcessModelFilter
                          processModelsId={selectedModels}
                          onProcessModelsChange={setSelectedModels}
                          options={processModels.filter((model) =>
                            tasks.todo
                              .concat(tasks.doing)
                              .some((task) => task.idprocessmodel === model.id)
                          )}
                        />
                      </Box>
                      <Box minWidth={200}>
                        {displayedTags.length > 0 && (
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            fontWeight="bold"
                          >
                            {getTranslation(
                              translations,
                              "generic.filter.by.tag"
                            )}
                          </Typography>
                        )}
                        <LabelSearchSelect
                          tagList={displayedTags}
                          idtags={searchTags}
                          setSearchTags={setSearchTags}
                        />
                        {filteredTags.length > 5 && (
                          <Button
                            onClick={() => setShowAllTags((prev) => !prev)}
                            variant="text"
                            sx={{ mt: 1, ml: 4 }}
                          >
                            {showAllTags
                              ? getTranslation(
                                  translations,
                                  "generic.show.less"
                                )
                              : getTranslation(
                                  translations,
                                  "generic.show.all"
                                )}
                          </Button>
                        )}
                      </Box>
                      <Box minWidth={200}>
                        {priorities.length > 0 && (
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight="bold"
                          >
                            {getTranslation(
                              translations,
                              "generic.filter.by.priority"
                            )}
                          </Typography>
                        )}
                        <PrioritySearchSelect
                          priorities={priorities}
                          selectedPriorities={selectedPriorities}
                          setSelectedPriorities={setSelectedPriorities}
                        />
                      </Box>
                      <Box minWidth={200}>
                        {userDataKeys.length > 0 && (
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight="bold"
                          >
                            {getTranslation(
                              translations,
                              "generic.filter.by.userdata"
                            )}
                          </Typography>
                        )}
                        <UserDataFilter
                          availableKeys={userDataKeys}
                          selectedKey={selectedUserDataKey}
                          onSelectKey={handleSelectUserDataKey}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </Menu>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="primary"
                  badgeContent={
                    Object.keys(sortCriteria).filter(
                      (key) => sortCriteria[key] !== SortState.None
                    ).length
                  }
                >
                  <Button
                    // sx={{ pt: 2 }}
                    size="small"
                    id="order-button"
                    onClick={handleSortClick}
                    endIcon={<SortIcon />}
                    variant="outlined"
                  >
                    {getTranslation(translations, "generic.sort.caption")}
                  </Button>
                </Badge>
                <Menu
                  anchorEl={sortAnchorEl}
                  open={Boolean(sortAnchorEl)}
                  onClose={handleCloseSort}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {SUPPORTED_SORTS.map(
                    (
                      { field, label, checkDisabled } //giriamo un oggetto
                    ) => (
                      <MenuItem
                        disabled={Boolean(
                          checkDisabled && checkDisabled(selectedUserDataKey)
                        )}
                        key={field}
                        value={field}
                        onClick={() => handleSortOptionClick(field)}
                        selected={
                          sortCriteria[field] !== SortState.None &&
                          Boolean(sortCriteria[field])
                        } //fare un cast di un elemento = cambiare il tipo alla variabile, in questo caso l'abbiamo trasformato in un booleano
                      >
                        {label(selectedUserDataKey)}
                        {sortCriteria[field] !== SortState.None &&
                          Boolean(sortCriteria[field]) && (
                            <React.Fragment>
                              {sortCriteria[field] === SortState.Desc ? (
                                <ArrowDownwardIcon />
                              ) : (
                                <ArrowUpwardIcon />
                              )}
                            </React.Fragment>
                          )}
                      </MenuItem>
                    )
                  )}
                </Menu>
              </Stack>
            </Stack>
            <Stack
              sx={{ overflow: "hidden" }}
              overflow="auto"
              flex={1}
              spacing={2}
            >
              <Stack
                sx={{ overflow: "hidden" }}
                direction="row"
                overflow="auto"
                height="100%"
                spacing={2}
              >
                <Stack
                  sx={{
                    overflow: "hidden",
                    minWidth: 340,
                    width: 340,
                    pb: 2,
                  }}
                  height="100%"
                >
                  <ToDoPanel
                    labelCompleteMode={completeLabel}
                    setDetail={setDetail}
                    goToDoing={fromTodoTo_Doing}
                    dragItem={dragItem}
                    all={all}
                    userId={user.userId}
                    switchChecked={switchChecked}
                    administrator={organization.administrator}
                    processedTasks={processedToDoTasks}
                    showOnlyIdUserZero={showOnlyIdUserZero}
                    setShowOnlyIdUserZero={setShowOnlyIdUserZero}
                  />
                </Stack>
                <Stack
                  sx={{
                    overflow: "hidden",
                    minWidth: 340,
                    width: 340,
                    pb: 2,
                  }}
                  height="100%"
                >
                  <DoingPanel
                    labelCompleteMode={completeLabel}
                    setDetail={setDetail}
                    goToDone={fromDoingTo_Done}
                    goTodo={fromDoingTo_Todo}
                    handleSequenceFlowChoice={fromDoingTo_Sequence}
                    dragItem={dragItem}
                    all={all}
                    userId={user.userId}
                    switchChecked={switchChecked}
                    administrator={organization.administrator}
                    processedTasks={processedDoingTasks}
                  />
                </Stack>
                <Stack
                  sx={{
                    overflow: "hidden",
                    minWidth: 340,
                    width: 340,
                  }}
                  height="100%"
                >
                  {/*
                    <Typography
                    sx={{ flex: 1, textAlign: "center" }}
                    variant="h6"
                  >
                    {getTranslation(
                      translations,
                      "pm.label.process_status.done"
                    )}
                  </Typography>
                     */}
                  <DonePanel dragItem={dragItem} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DragDropContext>
        {/* <Snackbar
          open={undoSnackbar}
          autoHideDuration={5000}
          onClose={() => setUndoSnackbar(false)}
          message="Note archived"
          action={
            <React.Fragment>
              <Button
                color="primary"
                size="small"
                onClick={() => setUndoSnackbar(false)}
              >
                UNDO
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setUndoSnackbar(false)}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        /> */}
      </React.Fragment>
    );
  } else {
    return (
      <Container
        sx={{
          display: "flex",
          JustifyContent: "center",
          alignItems: "center",
        }}
      >
        {getTranslation(translations, "placeholders.organization.select.label")}
      </Container>
    );
  }
};

export default MyActivities;
