import {
  Add,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
  Help,
  PrecisionManufacturing,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { organizationsApi, robotsApi } from "api";
import { getTranslation } from "common";
import { SearchBar } from "components";
import AvatarRem from "components/AvatarRem";
import UserPersonalRobots from "components/UserPersonalRobots";
import { RobotDialog } from "components/dialogs";
import DialogHeader from "components/dialogs/DialogHeader";
import {
  useRobots,
  useSnackbar,
  useTranslations,
  useVersions,
  useSelectedOrganization,
  useUser,
  useOrganizations,
} from "hooks";
import { Robot } from "hooks/useRobots";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Robots = () => {
  const [search, setSearch] = useState<string>("");
  const [userOrganizations] = useOrganizations();
  const [create, setCreate] = useState<boolean>(false);
  const [edit, setEdit] = useState<Robot | undefined>(undefined);
  const [confirmDelete, setConfirmDelete] = useState<Robot | undefined>(
    undefined
  );
  const { getVersion, updateVersion } = useVersions();
  const { sendSnack } = useSnackbar();
  const [robots, getRobots] = useRobots("organization");
  //  const [userRobots, getUserRobots] = useRobots("user");
  const translations = useTranslations();
  const organization = useSelectedOrganization();
  const user = useUser();
  const isAdmin = organization?.administrator;
  const [loadingOrgs, setLoadingOrgs] = useState<number[]>([]); //inizializziamo lo stato come un'array vuoto
  const [expandedRobots, setExpandedRobots] = useState<number[]>([]);

  // const handleCardClick = (robot: Robot) => {
  //   setSelectedRobot(robot);
  // };

  const toggleOrganizations = (robotId: number) => {
    setExpandedRobots((prev) =>
      prev.includes(robotId)
        ? prev.filter((id) => id !== robotId)
        : [...prev, robotId]
    );
  };

  const handleChangeOrganization = async (robotId: number, orgId: number) => {
    // aggiunge l'orgId all'array di caricamento, disabilitando il checkbox (riga 203)
    setLoadingOrgs((currentLoadingOrgs) => [...currentLoadingOrgs, orgId]); //callback di stato che riceve l'attuale stato e ci torna il nuovo array con il nuovo orgid
    try {
      const robot = robots.find((robot) => robot.id === robotId);
      let data;
      if (
        robot?.organizations.some(
          (organization) => String(organization.id) === String(orgId)
        )
      ) {
        //controlliamo se l'orgid  è già presente nell'array delle organizzazioni del robot

        const response = await organizationsApi.delete(
          `${orgId}/robots/${robotId}`
        ); //se c'è lo rimuoviamo
        data = response.data;
      } else {
        const response = await organizationsApi.put(
          `${orgId}/robots/${robotId}`
        ); //se non c'è lo mettiamo
        data = response.data;
      }
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
      onSave();
    } catch (error) {
    } finally {
      // rimuove l'orgId dall'array di caricamento, disabilitando il checkbox (riga 203)
      setLoadingOrgs((currentLoadingOrgs) =>
        currentLoadingOrgs.filter((id) => id !== orgId)
      );
    }
  };
  const handleDeleteRobot = async () => {
    try {
      const { data } = await robotsApi.delete(`${confirmDelete?.id}`);
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
      setConfirmDelete(undefined);
      getRobots();
    } catch {
    } finally {
    }
  };
  const onSave = async () => {
    getRobots();
    updateVersion(-1);
  };
  // const handleOpenUserRobotsDialog = () => {
  //   getUserRobots();
  //   setOpenUserRobotsDialog(true);
  // };
  // const handleCloseUserRobotsDialog = () => {
  //   setOpenUserRobotsDialog(false);
  // };

  const updateRobotsList = async () => {
    await getRobots();
  };

  return (
    <React.Fragment>
      {isAdmin && (
        <>
          <RobotDialog
            onSave={onSave}
            open={create}
            onClose={() => setCreate(false)}
            userId={user?.userId.toString()}
          />
          <RobotDialog
            onSave={onSave}
            open={Boolean(edit)}
            defaultRobot={edit}
            onClose={() => setEdit(undefined)}
            userId={user?.userId.toString()}
          />
          <Dialog
            open={Boolean(confirmDelete)}
            onClose={() => setConfirmDelete(undefined)}
          >
            <DialogHeader onClose={() => setConfirmDelete(undefined)}>
              {getTranslation(translations, "generic.pleaseconfirm")}
            </DialogHeader>
            <DialogContent>
              {getTranslation(translations, "robots.confirm.delete")}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmDelete(undefined)}
                variant="contained"
                size="small"
              >
                {getTranslation(translations, "generic.button.cancel")}
              </Button>
              <Button
                onClick={handleDeleteRobot}
                variant="contained"
                size="small"
              >
                {getTranslation(translations, "generic.button.confirm")}
              </Button>
            </DialogActions>
          </Dialog>
          <Container maxWidth="lg">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h5">
                  {getTranslation(translations, "robots.name")}
                </Typography>
                <IconButton size="small" to={"help"} component={Link}>
                  <Help fontSize="small" />
                </IconButton>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <SearchBar
                  setFilter={setSearch}
                  id="search-robots"
                  placeholder={getTranslation(
                    translations,
                    "pm.organizationchart.ricerca.placeholder"
                  )}
                />
                <UserPersonalRobots onRobotUpdate={updateRobotsList} />
                {/* <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => setCreate(true)}
                >
                  {getTranslation(translations, "add")}
                </Button> */}
              </Stack>
            </Stack>
            <Grid container spacing={2}>
              {robots
                .filter(
                  (robot) =>
                    robot.name.toLowerCase().includes(search.toLowerCase()) ||
                    robot.description
                      .toLowerCase()
                      .includes(search.toLowerCase())
                )
                .map((robot) => (
                  <Grid
                    item
                    sm={6}
                    md={4}
                    lg={3}
                    xs={12}
                    key={`robot-${robot.id}`}
                  >
                    <Card
                      elevation={3}
                      sx={{
                        height: "auto",
                        minHeight: 125,
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "15px",
                      }}
                    //onClick={() => handleCardClick(robot)}
                    >
                      <CardHeader
                        sx={{
                          display: "grid",
                          gridTemplateColumns:
                            "0fr minmax(20px, 1fr) minmax(50px, 0fr)",
                        }}
                        subheaderTypographyProps={{
                          sx: {
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          },
                        }}
                        action={
                          <Box>
                            {robot.iduserowner === user?.userId && (
                              <Box sx={{ display: "flex" }}>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEdit(robot);
                                  }}
                                  size="small"
                                >
                                  <Edit fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmDelete(robot);
                                  }}
                                  size="small"
                                >
                                  <Delete fontSize="small" />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        }
                        titleTypographyProps={{
                          sx: {
                            overflowX: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                          },
                        }}
                        title={robot?.name}
                        subheader={
                          (robot.user?.firstname || '') + " " + (robot.user?.lastname || '')
                        }
                        avatar={
                          <AvatarRem
                            key={`${robot.id}-${getVersion(-1)}`}
                            icon={robot.picture || ""}
                          >
                            <PrecisionManufacturing
                              sx={{ width: "50%", height: "50%" }}
                            />
                          </AvatarRem>
                        }
                      />
                      <CardActions disableSpacing>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleOrganizations(robot.id);
                          }}
                          endIcon={
                            expandedRobots.includes(robot.id) ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          }
                          sx={{
                            opacity: robot.iduserowner === user?.userId ? 1 : 0,
                            pointerEvents:
                              robot.iduserowner === user?.userId
                                ? "auto"
                                : "none",
                          }}
                        >
                          {expandedRobots.includes(robot.id)
                            ? getTranslation(translations, "hide.details")
                            : getTranslation(translations, "show.details")}
                        </Button>
                      </CardActions>
                      <Collapse in={expandedRobots.includes(robot.id)}>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            {robot.description}
                          </Typography>
                          <Stack direction="column">
                            <Typography sx={{ mt: 2, fontWeight: "bold" }}>
                            {getTranslation(translations, "menu.left.organizations")}
                            </Typography>
                            {userOrganizations
                              .filter((item) => item.administrator)
                              .map((org) => (
                                <FormControlLabel
                                  key={org.id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={robot.organizations.some(
                                        (organization) =>
                                          String(organization.id) ===
                                          String(org.id)
                                      )}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        handleChangeOrganization(
                                          robot.id,
                                          Number(org.id)
                                        );
                                      }}
                                      disabled={loadingOrgs.includes(
                                        org.id as number
                                      )}
                                    />
                                  }
                                  label={org.name}
                                />
                              ))}
                          </Stack>
                        </CardContent>
                      </Collapse>
                    </Card>
                  </Grid>
                ))}
              <Grid item sm={6} md={4} lg={3} xs={12} key="robot-add">
                <Card
                id="new-robot-btn"
                  elevation={0}
                  sx={{
                    border: "2px dashed grey",
                    minHeight: 125,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "15px",
                  }}
                >
                  <CardActionArea
                    sx={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setCreate(true)}
                  >
                    <Typography variant="body2">{getTranslation(translations, "generic.add")}</Typography>
                    <Add htmlColor="grey" />
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {!isAdmin && (
        <Typography variant="body1">
          {getTranslation(translations, "generic.no.permission")}
        </Typography>
      )}
      {/* <Dialog
        fullWidth
        open={Boolean(selectedRobot)}
        onClose={() => setSelectedRobot(null)}
      >
        <DialogTitle>{selectedRobot?.name}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 100 }}>
            <AvatarRem
              sx={{ width: 80, height: 80, mb: 2 }}
              icon={selectedRobot?.picture || undefined}
            >
              <PrecisionManufacturing sx={{ width: '50%', height: '50%' }} />
            </AvatarRem>
            <Typography variant="body1" gutterBottom>{selectedRobot?.description}</Typography>
            <Typography variant="body2" color="text.secondary">
              Owner: {selectedRobot?.user.firstname}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedRobot(null)}>Close</Button>
        </DialogActions>
      </Dialog> */}

    </React.Fragment>
  );
};

export default Robots;
