import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    FormControlLabel,
    Box,
    IconButton,
    Typography,
} from "@mui/material";
import { useRobots, useTranslations, useOrganizations, useSnackbar, useVersions, useUser } from "hooks";
import { getTranslation } from "common";
import { organizationsApi, robotsApi } from "api";
import AvatarRem from './AvatarRem';
import { Close, Delete, Edit, PrecisionManufacturing } from '@mui/icons-material';
import { Robot } from 'hooks/useRobots';
import { RobotDialog } from './dialogs';
import NoRobots from "../image/NoRobots.png"

interface UserPersonalRobotsProps {
    onRobotUpdate: () => Promise<void>;
}

const UserPersonalRobots: React.FC<UserPersonalRobotsProps> = ({ onRobotUpdate }) => {
    const user = useUser();
    const { getVersion } = useVersions();
    const [openUserRobotsDialog, setOpenUserRobotsDialog] = useState<boolean>(false);
    const [userRobots, getUserRobots] = useRobots("user");
    const [userOrganizations] = useOrganizations();
    const translations = useTranslations();
    const { sendSnack } = useSnackbar();
    const [loadingOrgs, setLoadingOrgs] = useState<number[]>([]);
    const [editRobot, setEditRobot] = useState<Robot | undefined>(undefined);
    const [confirmDelete, setConfirmDelete] = useState<Robot | undefined>(undefined);

    const handleOpenUserRobotsDialog = () => {
        getUserRobots();
        setOpenUserRobotsDialog(true);
    };

    const handleCloseUserRobotsDialog = () => {
        setOpenUserRobotsDialog(false);
    };
    const handleEditRobot = (robot: Robot) => {
        setEditRobot(robot);
    };

    const handleDeleteRobot = async () => {
        if (!confirmDelete) return;

        try {
            const { data } = await robotsApi.delete(`${confirmDelete.id}`);
            sendSnack({
                message: data.responsemessage,
                type: "success",
            });
            setConfirmDelete(undefined);
            await getUserRobots();
            await onRobotUpdate();
        } catch { }
    };

    const onSaveRobot = async () => {
        await getUserRobots();
        await onRobotUpdate();
        setEditRobot(undefined);
    };


    const handleChangeOrganization = async (robotId: number, orgId: number) => {
        setLoadingOrgs(currentLoadingOrgs => [...currentLoadingOrgs, orgId]);

        try {
            const robot = userRobots.find(robot => robot.id === robotId);
            let data;
            if (robot?.organizations.some((organization) => String(organization.id) === String(orgId))) {
                const response = await organizationsApi.delete(`${orgId}/robots/${robotId}`);
                data = response.data;
            } else {
                const response = await organizationsApi.put(`${orgId}/robots/${robotId}`);
                data = response.data;
            }
            sendSnack({
                message: data.responsemessage,
                type: "success",
            });
            await getUserRobots();
            await onRobotUpdate()
        } catch {
        } finally {
            setLoadingOrgs(currentLoadingOrgs => currentLoadingOrgs.filter(id => id !== orgId));
        }
    };

    return (
        <>
            <Button
                sx={{
                    whiteSpace: 'nowrap',
                }}
                variant="outlined"
                onClick={handleOpenUserRobotsDialog}
            >
                {getTranslation(translations, "my.robots")}
            </Button>
            <Dialog
                open={openUserRobotsDialog}
                onClose={handleCloseUserRobotsDialog}
                fullWidth
                maxWidth="md"
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <DialogTitle>{getTranslation(translations, "my.robots")}</DialogTitle>
                    <DialogActions>
                        <IconButton onClick={handleCloseUserRobotsDialog}>
                            <Close />
                        </IconButton>
                    </DialogActions>
                </Box>
                <DialogContent>
                    {userRobots.length === 0 ? (
                        <React.Fragment>
                            <Box component="img"
                                src={NoRobots}
                                alt="No robots"
                                sx={{
                                    width: '100%',
                                    maxHeight: 380,
                                    height: 'auto',
                                    objectFit: 'contain'
                                }}
                            />
                            <Typography variant="h6" sx={{textAlign:"center"}}>
                                {getTranslation(translations, "no.robots")}
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{getTranslation(translations, "generic.icon")}</TableCell>
                                    <TableCell>{getTranslation(translations, "robots.name")}</TableCell>
                                    <TableCell>{getTranslation(translations, "generic.description")}</TableCell>
                                    <TableCell>{getTranslation(translations, "menu.left.organizations")}</TableCell>
                                    <TableCell>{getTranslation(translations, "generic.action.title")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userRobots.map((robot) => (
                                    <TableRow key={robot.id}>
                                        <TableCell>
                                            <Box>
                                                <AvatarRem
                                                    sx={{ width: 80, height: 80 }}
                                                    key={`${robot.id}-${getVersion(-1)}`}
                                                    icon={robot.picture || undefined}
                                                >
                                                    <PrecisionManufacturing sx={{ width: '50%', height: '50%' }} />
                                                </AvatarRem>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }}>{robot.name}</TableCell>
                                        <TableCell sx={{ wordBreak: 'break-word' }}>{robot.description}</TableCell>
                                        <TableCell sx={{display:"flex", flexDirection:"column"}}>
                                                {userOrganizations.filter(({ administrator }) => administrator).map(org => (
                                                    <FormControlLabel
                                                        key={org.id}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                checked={robot.organizations.some((organization) => String(organization.id) === String(org.id))}
                                                                onChange={() => handleChangeOrganization(robot.id, Number(org.id))}
                                                                disabled={loadingOrgs.includes(org.id as number)}
                                                            />
                                                        }
                                                        label={org.name}
                                                    />
                                                ))}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleEditRobot(robot)} size="small">
                                                <Edit fontSize="small" />
                                            </IconButton>
                                            <IconButton onClick={() => setConfirmDelete(robot)} size="small">
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </DialogContent>
            </Dialog>
            <RobotDialog
                onSave={onSaveRobot}
                open={Boolean(editRobot)}
                defaultRobot={editRobot}
                onClose={() => setEditRobot(undefined)}
                userId={user?.userId.toString()}
            />
            <Dialog
                open={Boolean(confirmDelete)}
                onClose={() => setConfirmDelete(undefined)}
            >
                <DialogTitle>{getTranslation(translations, "generic.pleaseconfirm")}</DialogTitle>
                <DialogContent>
                    {getTranslation(translations, "robots.confirm.delete")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDelete(undefined)}
                        variant="contained"
                        size="small"
                    >
                        {getTranslation(translations, "generic.button.cancel")}
                    </Button>
                    <Button onClick={handleDeleteRobot} variant="contained" size="small">
                        {getTranslation(translations, "generic.button.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserPersonalRobots;