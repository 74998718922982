import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getTranslation, toDataUrl } from "common";
import ImagePicker from "components/ImagePicker";
import {
  useForm,
  useSelectedOrganization,
  useSnackbar,
  useTranslations,
} from "hooks";
import React, { useEffect, useState } from "react";
import DialogHeader from "./DialogHeader";
import { Validation, isRequired } from "hooks/useForm";
import { organizationsApi, robotsApi } from "api";
import { Robot } from "hooks/useRobots";
import { ContentCopy } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from "dayjs";

interface RobotDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  defaultRobot?: Robot;
  userId?: string;
}

const RobotDialog: React.FC<RobotDialogProps> = ({
  open,
  onClose,
  defaultRobot = undefined,
  onSave,
  userId,
}) => {
  const [created, setCreated] = useState(false);
  const organization = useSelectedOrganization();
  const translations = useTranslations();
  const { sendSnack } = useSnackbar();
  const validations: Validation[] = [
    ({ name }) =>
      isRequired(name) || {
        name: getTranslation(translations, "pm.newfolder.required.name"),
      },
  ];
  const { values, changeHandler, errors, isValid, touched, reset } = useForm(
    { ...defaultRobot, _picture: null } || {},
    validations
  );

  useEffect(() => {
    reset(defaultRobot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRobot]);

  useEffect(() => {
    if (!open) {
      reset();
      setCreated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleRefreshRobotApiKey = async () => {
    try {
      const robotId = created ? values.id : defaultRobot?.id;
      const { data } = await robotsApi.put(`${robotId}/refreshtoken`, {});
      reset({
        ...defaultRobot, ...values, apikey: data.token, expiration_date: data.expiration_date
      })
      sendSnack({
        message: data.message,
        type: "success",
      });
    } catch (error) {
    } finally {
      onSave();
    }
  };

  const handleSaveRobot = async () => {
    if (defaultRobot || created) {
      try {
        let picture = null;
        if (values._picture) {
          picture = await toDataUrl(values._picture);
        }
        const robotId = created ? values.id : defaultRobot?.id;
        const { data } = await robotsApi.put(`${robotId}`, {
          ...values,
          picture,
        });
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        onSave();
        onClose();
      } catch {
      } finally {
      }
    } else {
      try {
        let picture = null;
        if (values._picture) {
          picture = await toDataUrl(values._picture);
        }
        const { data } = await robotsApi.post("", {
          ...values,
          picture,
        });
        organizationsApi.put(`${organization?.id}/robots/${data.id}`);
        const { data: robot } = await robotsApi.get<Robot>(`${data.id}`);
        reset({ ...robot, expiration_date: dayjs(robot.expiration_date).format("DD-MM-YYYY HH:mm") });
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
        setCreated(true);
        onSave();
      } catch {
      } finally {
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h5">
          {getTranslation(
            translations,
            "configure.robot.dialog.title"
          )}</Typography>
      </DialogHeader>
      <DialogContent>
        <Stack direction="row" sx={{ pt: (_) => _.spacing(0.7) }} spacing={2}>
          <Box flex={1}>
            <ImagePicker
              photoUrl={values._picture}
              relativePath={values.picture}
              setPhotoUrl={(value) => changeHandler("_picture", value)}
            />
          </Box>
          <Grid component="form" flex={2} container spacing={2}>
            <Grid item xs={12}>
              <TextField
              id="robot-name-field"
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(
                  translations,
                  "register.registration.name.label"
                )}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                value={values?.name || ""}
                onChange={({ target }) => changeHandler("name", target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
              id="robot-description-field"
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(
                  translations,
                  "pm.errors.label.description"
                )}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                value={values?.description || ""}
                onChange={({ target }) =>
                  changeHandler("description", target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={getTranslation(
                          translations,
                          "generic.copy_to_clipboard"
                        )}
                      >
                        <span>
                          <IconButton
                          id="copy-robot-apikey"
                            disabled={!values.apikey}
                            onClick={() =>
                              navigator.clipboard.writeText(values.apikey)
                            }
                            size="small"
                            type="button"
                            aria-label="copy-apikey"
                          >
                            <ContentCopy fontSize="small" />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton
                      id="refresh-robot-apikey"
                        onClick={handleRefreshRobotApiKey}
                        //   variant="outlined"
                        size="small"
                        disabled={!created && !defaultRobot}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
                disabled={!values.apikey}
                size="small"
                label={
                  (!values.apikey)
                    ? getTranslation(
                      translations,
                      "robots.label.save_to_obtain_api_key"
                    )
                    : getTranslation(translations, "robots.label.api_key")
                }
                helperText={
                  // getTranslation(translations, "robots.key.expiration.date") + 
                  // ": " + 
                  values.expiration_date
                    ? `${getTranslation(translations, "robots.key.expiration.date")}: ${values.expiration_date}`
                    : undefined
                }
                value={values?.apikey || ""}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(translations, "robot.owner.label")}
                value={defaultRobot ? defaultRobot.iduserowner : userId}
                disabled
              />
            </Grid> */}
            <Grid
              xs={12}
              item
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button onClick={onClose} variant="outlined" size="small">
                  {getTranslation(translations, "generic.button.cancel")}
                </Button>
                <Button
                id="save-robot-btn"
                  onClick={handleSaveRobot}
                  variant="outlined"
                  size="small"
                  disabled={!isValid}
                >
                  {getTranslation(translations, "generic.button.savechanges")}
                </Button>
                {/* <Button
                  onClick={handleRefreshRobotApiKey}
                  variant="outlined"
                  size="small"
                // disabled={!isValid}
                >
                  <RefreshIcon />
                </Button> */}
                {/* <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="Organizzazione"
                  value={organization?.name || ""}
                  disabled
                /> */}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RobotDialog;
